import "./index.css";
import React, { Component } from "react";
import { Icon } from "semantic-ui-react";

class TopTabBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arr: [],
      expandSideBar: false,
    }
  }
  hideBar = () => {
    this.setState({
      expandSideBar: false,
    });
  }
  render() {
    if (this.props.width < 450) {
      if (!this.state.expandSideBar) {
        return (
          <div
            className="TB-cornerButton"
            onClick={() => this.setState({expandSideBar: true})}
          >
            <Icon name='bars' size='big' className="TB-white" />
          </div>
        );
      } else {
        return (
          <div className="TB-sideBar">
            <a
              href="http://salishlanguagegames.com/KidsZone/"
              className="homeColor TB-menuButton"
              onClick={this.hideBar}>
              <div>Home</div>
            </a>
            <a
              href="http://salishlanguagegames.com/KidsZone/"
              className="storyColor TB-menuButton"
              onClick={this.hideBar}>
              <div>Stories</div>
            </a>
            <a
              href="http://salishlanguagegames.com/KidsZone/EndlessMode/"
              className="endlessColor TB-menuButton"
              onClick={this.hideBar}>
              <div>Endless</div>
            </a>
            <a
              href="http://salishlanguagegames.com/KidsZone/HistoryGame/"
              className="historyColor TB-menuButton"
              onClick={this.hideBar}>
              <div>History Game</div>
            </a>
            <a
              href="http://www.salishlanguagegames.com/dictionary"
              className="dictionaryColor TB-menuButton"
              onClick={this.hideBar}>
              <div>Dictionary</div>
            </a>
            <a
              href="http://salishlanguagegames.com/KidsZone/Credits/"
              className="creditsColor TB-menuButton"
              onClick={this.hideBar}>
              <div>Credits</div>
            </a>
            <div className="closeColor TB-menuButton" onClick={this.hideBar}>
              Close
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="topBar-fullBar">
          <a
            href="http://salishlanguagegames.com/KidsZone/"
            className="topBar-tab homeColor">
            <div className="TB-topTabFont">Home</div>
          </a>
          <a
            href="http://salishlanguagegames.com/KidsZone/"
            className="topBar-tab storyColor">
            <div className="TB-topTabFont">Stories</div>
          </a>
          <a
            href="http://salishlanguagegames.com/KidsZone/EndlessMode/"
            className="topBar-tab endlessColor">
            <div className="TB-topTabFont">Endless</div>
          </a>
          <a
            href="http://salishlanguagegames.com/KidsZone/HistoryGame/"
            className="topBar-tab historyColor">
            <div className="TB-topTabFont">History Game</div>
          </a>
          <a
            href="http://www.salishlanguagegames.com/dictionary"
            className="topBar-tab dictionaryColor">
            <div className="TB-topTabFont">Dictionary</div>
          </a>
          <a
            href="http://salishlanguagegames.com/KidsZone/Credits/"
            className="topBar-tab creditsColor">
            <div className="TB-topTabFont">Credits</div>
          </a>
        </div>
      );
    }
  }
}

export default TopTabBar;
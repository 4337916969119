import "./index.css";
import React, { useState, useEffect } from "react";
import NewSearchBar from '../NewSearchBar'
import { Icon, Image } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import PropTypes from "prop-types";

const AUDIO_PRE = 'https://storage.googleapis.com/dict2test.appspot.com/Test/public/Audio/';
const IMAGE_PRE = 'https://storage.googleapis.com/dict2test.appspot.com/Test/public/Images/';

const Container = (props) => {
  let expandedStyle = {
    backgroundColor: "rgba(0,0,0,.5)",
    padding: 10,
    margin: 5,
    width: "96%",
    borderRadius: 5,
  };
  let unexpandedStyle = {
    backgroundColor: "rgba(0,0,0,.5)",
    padding: 10,
    margin: 5,
    width: props.width / 10,
    minWidth: 230,
    maxWidth: 300,
    borderRadius: 5,
  };
  if (props.expandedCat === props.cat) {
    return (
      <div style={expandedStyle}>
        {props.children}
      </div>
    );
  } else {
    return (
      <div style={unexpandedStyle}>
        {props.children}
      </div>
    );
  }
}
let audioClip = null;
const Dictionary = props => {
  const [expandedCat, setexpandedCat] = useState(null);
  const [searchBarHeight, setsearchBarHeight] = useState(50);
  const [allData, setAllData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {

    let formatData = [];
    props.data.map(datum => {
      formatData.push({ id: datum.id, homeName: datum.homeName, english: datum.english, salish: datum.salish, audio1: datum.audio1, image1: datum.image1 })
    });
    props.storyVocab.map(datum => {
      formatData.push({ id: `${datum.id}Story`, homeName: datum.storyGame, english: datum.english, salish: datum.salish, audio1: datum.audio1, image1: datum.image1 })
    });
    setAllData(formatData);
    setDisplayData(formatData);
    let uniqueCategories = [];
    formatData.map((datum) => {
      if (!uniqueCategories.includes(datum.homeName)) {
        uniqueCategories.push(datum.homeName);
      }
    });
    setCategories(uniqueCategories)
  }, [props.data, props.storyVocab]);
  const setSearchData = (value) => {
    let newData = [];
    allData.map(datum => {
      if (datum.english.toLowerCase().includes(value)) {
        newData.push(datum);
      } else if (datum.salish.includes(value)) {
        newData.push(datum);
      }
    })
    setDisplayData(newData);
    let uniqueCategories = [];
    newData.map((datum) => {
      if (!uniqueCategories.includes(datum.homeName)) {
        uniqueCategories.push(datum.homeName);
      }
    });
    setCategories(uniqueCategories);
  };
  const openCat = (cat) => {
    document.getElementById('sound1').play();
    setexpandedCat(cat);
  }
  const closeCat = (cat) => {
    document.getElementById('sound2').play();
    setexpandedCat(null);
  }
  const playAudio = (audio) => {
    if (audioClip !== null) {
      audioClip.pause();
      audioClip.currentTime = 0;
      audioClip = null;
    }
    audioClip = new Audio(`${AUDIO_PRE}${audio}`);
    audioClip.play();
  }
  let dictionaryFullscreen = {
    width: props.width,
    minHeight: props.height,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: 'white',
  };
  return (
    <div style={dictionaryFullscreen} className="dictionary-fullscreen">
      <audio src='https://storage.googleapis.com/dict2test.appspot.com/Test/public/Audio/expandAcc.mp3' id='sound1' />
      <audio src='https://storage.googleapis.com/dict2test.appspot.com/Test/public/Audio/compressAcc.mp3' id='sound2' />
      <NewSearchBar
        setSearchData={setSearchData}
        setBarHeight={setsearchBarHeight}
        barHeight={searchBarHeight}
        height={props.height}
        width={props.width}
      />
      <div id='dictionaryContentContainer' className='dictionary-contentContainer dictionary-contentContainerNormal'>
        {categories.map(cat => {
          return (
            <Container width={props.width} key={cat} cat={cat} expandedCat={expandedCat}>
              {expandedCat === cat ? (
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => closeCat()}
                  >
                    <div>{cat}</div>
                    <Icon name="angle up" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {displayData.map((datum) => {
                      if (datum.homeName === cat) {
                        return (
                          <div
                            key={datum.id}
                            style={{
                              width: 200,
                              margin: 5,
                              padding: 5,
                              backgroundColor: "rgba(0,0,0,.5)",
                              borderRadius: 7,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                          >
                            {datum.salish} ({datum.english})
                            {datum.image1 !== "" ? (
                              <Image
                                fluid
                                src={`${IMAGE_PRE}${datum.image1}`}
                                style={{
                                  height: "auto",
                                  width: "100%",
                                }}
                              />
                            ) : null}
                            {datum.audio1 !== "" ? (
                              <div
                                onClick={() => playAudio(datum.audio1)}
                                style={{
                                  height: 30,
                                  backgroundColor: "rgba(86,172,238,1)",
                                  marginTop: 7,
                                  borderRadius: 30,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Icon name="play" size="large" />
                              </div>
                            ) : null}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  onClick={() => openCat(cat)}
                >
                  <div>{cat}</div>
                  <Icon name="angle down" />
                </div>
              )}
            </Container>
          );
        })}
      </div>
    </div>
  );
}

export default Dictionary;

Dictionary.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array,
  storyVocab: PropTypes.array
}

import "./App.css";
import React, { Component } from 'react';

import Dictionary from './Components/Dictionary';
import TopTabBar from "./Components/TopBar/index";

import Data from './Data/data.json';
import StorybookVocab from './Data/storybookVocab.json';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.windowResized);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.windowResized);
  }
  windowResized = () => {
    this.setState({
      width: document.body.getBoundingClientRect().width,
      height: document.body.getBoundingClientRect().height,
    });
  };
  render() {
    let fullscreen = {
      // height: this.state.height,
      width: this.state.width,
    }
    return (
      <div className="App-fullscreen" style={fullscreen}>
        <TopTabBar />
        <Dictionary
          data={Data}
          storyVocab={StorybookVocab}
          width={this.state.width}
          height={this.state.height}
        />
      </div>
    );
  }
}

export default App;
import "./index.css";
import React from "react";
// import { Icon, Input } from "semantic-ui-react";
// import "semantic-ui-css/semantic.min.css";

import PropTypes from "prop-types";

const Keyboard = (props) => {
  let specialChars = [
    "á",
    "é",
    "í",
    "ó",
    "ú",
    "y̓",
    "č̓",
    "č",
    "c̓",
    "š",
    "ʷ",
    "k̓",
    "l̓",
    "ł",
    "n̓",
    "x̣",
    "t̓",
    "m̓",
    "ƛ̓",
    "ʔ",
    "q̓",
    "p̓",
    "k̓̓",
    "t̓̓",
    "w̓",
  ];
  let keyStyle = {
    height: (props.width*.03)*.66,
    width: props.width*.03,
  };
  let charStyle = {
    color: "black",
    fontSize: 20,
    position: 'relative',
    top: keyStyle.height/6,
  };
  charStyle.fontSize = keyStyle.height/2.2;
  if (charStyle.fontSize < 12) {
    charStyle.fontSize = 12;
  }
  return (
    <div className='keyboard-fullBoard'>
      {specialChars.map(char => {
        return (
          <div
            key={`${char}`}
            onClick={() => props.setNewValue(char)}
            style={keyStyle} // width and height (transfer to css with calc())
            className='keyboard-keys'
          >
            <div style={charStyle}>{char}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Keyboard;

Keyboard.propTypes = {
  setNewValue: PropTypes.func,
  width: PropTypes.number,
};

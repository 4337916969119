import "./index.css";
import React, { useState } from "react";
import { Icon, Input } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import PropTypes from "prop-types";
import Keyboard from "../Keyboard/index";

const NewSearchBar = (props) => {
  const [expanded, setexpanded] = useState(false);
  const [keyboard, setkeyboard] = useState(false);
  const [value, setvalue] = useState("");
  let setNewValue = (char) => {
    props.setSearchData(value + char);
    setvalue(value + char);
  };
  const changeInput = (e) => {
    let lowerCase = e.target.value.toLowerCase();
    props.setSearchData(lowerCase);
    setvalue(e.target.value);
  };
  const expandContract = () => {
    let animate = document.getElementById("expandCompress");
    if (expanded) {
      if (keyboard) {
        expandKeyboard();
      }
      setexpanded(false);
      animate.addEventListener("animationend", () => {
        animate.className =
          "newSearchBar-expandContract newSearchBar-testAnimateShortened";
        setkeyboard(false);
        setexpanded(false);
      });
      animate.className =
        "newSearchBar-expandContract newSearchBar-testAnimateShorten";
    } else {
      animate.addEventListener("animationend", () => {
        animate.className =
          "newSearchBar-expandContract newSearchBar-testAnimateExpanded";
        setexpanded(true);
      });
      animate.className =
        "newSearchBar-expandContract newSearchBar-testAnimateExpand";
    }
  };
  const expandKeyboard = () => {
    let animate = document.getElementById("expandCompress");
    let dictionaryAnimate = document.getElementById('dictionaryContentContainer');
    if (keyboard) {
      setkeyboard(false);
      animate.addEventListener('animationend', () => {
        dictionaryAnimate.className =
          "dictionary-contentContainer dictionary-contentContainerNormal";
        animate.className =
          "newSearchBar-expandContract newSearchBar-testAnimateExpanded";
        setkeyboard(false);
        props.setBarHeight(120);
      });
      dictionaryAnimate.className = 'dictionary-contentContainer dictionary-contentContainerUp';
      animate.className =
        "newSearchBar-expandContract newSearchBar-testAnimateExpanded newSearchBar-keyboardShorten";
    } else {
      animate.addEventListener('animationend', () => {
        dictionaryAnimate.className =
          "dictionary-contentContainer dictionary-contentContainerPushed";
        animate.className =
          "newSearchBar-expandContract newSearchBar-testAnimateExpanded newSearchBar-keyboardExpanded";
        setkeyboard(true);
        props.setBarHeight(50);
      });
      dictionaryAnimate.className = 'dictionary-contentContainer dictionary-contentContainerDown';
      animate.className =
        "newSearchBar-expandContract newSearchBar-testAnimateExpanded newSearchBar-keyboardExpand";
    }
  }
  let searchBarOutter = {
    width: props.width + 10,
    borderRadius: 30,
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    top: 55,
    left: 10,
  };
  let searchBar = {
    display: "flex",
    alignItems: "center",
  };
  let inputWidth = props.width - 140;
  let searchContents = {};
  if (expanded) {
    searchContents = (
      <div id="expanded" style={searchBarOutter}>
        <div style={searchBar}>
          <div
            className='newSearchBar-innerButton'
            onClick={() => expandContract()}
          >
            <Icon size="big" name="close" />
          </div>
          {keyboard ? (
            <div className='newSearchBar-innerButton' onClick={() => expandKeyboard()}>
              <Icon size="big" name="angle up" />
            </div>
          ) : (
            <div className='newSearchBar-innerButton' onClick={() => expandKeyboard()}>
              <Icon size="big" name="angle down" />
            </div>
          )}
          <Input
            input={{ fluid: true }}
            value={value}
            style={{width: inputWidth}}
            rounded='true'
            icon="search"
            onChange={(e) => changeInput(e)}
          >
            <input style={{borderRadius: 50}}/>
            <Icon name="search" />
          </Input>
        </div>
        <div id='expandContractKeyboard'>
        {keyboard ? (
          <Keyboard width={props.width} setNewValue={setNewValue} />
        ) : null}
        </div>
      </div>
    );
  } else {
    props.setBarHeight(50);
    searchContents = (
      <div onClick={() => expandContract()}>
        <Icon size="big" name="search" />
      </div>
    );
  }
  return (
    <div className="newSearchBar-overlay">
      <div
        id="expandCompress"
        className="newSearchBar-expandContract newSearchBar-testAnimateShortened"
      >
        {searchContents}
      </div>
    </div>
  );
};

export default NewSearchBar;

NewSearchBar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  barHeight: PropTypes.number,
  setSearchData: PropTypes.func,
};
